<template>
  <div :id="anchorName" class="card-style2">
    <h2 v-if="name" class="dynamic-main-title tmw-40-32-15">{{ name }}</h2>
    <v-container class="inner-container inner-container--content">
      <v-row>
        <v-col v-for="(item, i) in items" :key="i" cols="12" sm="12" lg="6" xl="4" class="col-xxl-4">
          <FlipCardItem :item="item" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import listMixins from "./list.js";
import FlipCardItem from "@/components/dynamic/card/item/flipCardItem2.vue";
export default {
  mixins: [listMixins],
  components: {
    FlipCardItem,
  },
};
</script>